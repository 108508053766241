import './index.scss';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SettingsList from 'pages/Settings/List';
import Button from 'components/Button';
import Icon from 'components/Icon';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import type { BusinessUnit, Organization } from 'types/models';
import SendingActions from '../Actions';

type Props = {
  standalone?: boolean,
  data: BusinessUnit | Organization,
  onShowToast(message: string, status: ToastStatus): void,
};

const SignaturesItem = ({ onShowToast, data, standalone }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { letterHead, signatureEmail, signatureLetter } = data;
  const [openAction, setOpenAction] = useState<boolean>(false);
  const [email, setSenderEmail] = useState<string | null>();
  const [groupName, setGroupName] = useState<string | null>();
  const [identifier, setIdentifier] = useState<number>();
  const [handwritten, setHandwritten] = useState<string | null>(null);

  const isDefault = useMemo(() => 'corporateName' in data, [data]);

  useEffect(() => {
    if (isDefault) {
      const { sendingEmail, corporateName, signature } = data as Organization;
      setSenderEmail(sendingEmail);
      setGroupName(corporateName);
      setHandwritten(signature);
      return;
    }

    const { senderEmail, name, id, signatureHandwritten } = data as BusinessUnit;
    setSenderEmail(senderEmail);
    setGroupName(name);
    setIdentifier(id);
    setHandwritten(signatureHandwritten);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const listData = useMemo(() => [
    {
      label: t('customization:list-sending.sending-email'),
      value: email || '-',
    },
    {
      label: t('customization:list-sending.letter-head'),
      value: <SendingActions
        data={letterHead}
        businessUnitId={identifier}
        genreTranslate="m"
        type="pdf"
        title={t('customization:list-sending.letter-head')}
        name="letterHead"
        onShowToast={onShowToast}
      />,
    },
    {
      label: t('customization:list-sending.handwritten-signature'),
      value: <SendingActions
        data={handwritten}
        businessUnitId={identifier}
        type="img"
        title={t('customization:list-sending.handwritten-signature')}
        name={isDefault ? 'handwrittenSignature' : 'signature_handwritten'}
        onShowToast={onShowToast}
      />,
    },
    {
      label: t('customization:list-sending.letter-signature'),
      value: <SendingActions
        data={signatureLetter}
        businessUnitId={identifier}
        type="text"
        title={t('customization:list-sending.letter-signature')}
        name="signatureLetter"
        onShowToast={onShowToast}
      />,
    },
    {
      label: t('customization:list-sending.email-signature'),
      value: <SendingActions
        data={signatureEmail}
        businessUnitId={identifier}
        type="text"
        title={t('customization:list-sending.email-signature')}
        name="signatureEmail"
        onShowToast={onShowToast}
      />,
    },
  ], [letterHead, email, handwritten, signatureEmail, signatureLetter, t, isDefault, identifier, onShowToast]);

  const classNames = classnames('SignaturesItem', {
    'SignaturesItem--collapse': !standalone,
  });

  return (
    <div className={classNames}>
      {!standalone && (
        <div className="SignaturesItem--collapse__header">
          <div className="SignaturesItem--collapse__name">{groupName}</div>
            {isDefault && (
              <div className="SignaturesItem--collapse__type">
                {t('common:by-default')}
              </div>
            )}
            <Button
              variant="light"
              onClick={() => setOpenAction(!openAction)}
              className="SignaturesItem--collapse__toggle"
              aria-expanded={openAction}
            >
              {!openAction ? <Icon name="caret-down" /> : <Icon name="caret-up" />}
            </Button>
        </div>
      )}
      {(standalone || openAction) && (
        <div className="SignaturesItem__list">
          <div className="Sending__list__items">
            <SettingsList data={listData.slice(0, 3)} />
          </div>
          <div className="Sending__list__items">
            <SettingsList data={listData.slice(3, 6)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(SignaturesItem);
