import './index.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useTranslation } from 'react-i18next';
import Misc from 'types/misc';
import getI18nOperatorComparison from 'utils/getI18nOperatorComparison';
import dateFormatIso from 'utils/dateFormatIso';
import FormControl from 'components/FormControl';
import FormSelectMinimal from 'components/FormSelectMinimal';
import {
  AllOperators,
  ComparisonOperators,
  EqualityOperators,
  LogicOperator,
  SelectorCondition,
  SelectorCreditFilterType,
  SelectorDateSign,
  SelectorDebitFilterType,
  SelectorDispatchReference,
  SelectorDispatchType,
  SelectorFilterPriority,
  SelectorPeriod,
  SelectorsFieldsData,
  SelectorsFieldsTypes,
  SelectorsRefDateEAV,
} from 'types/models';
import { getFieldDescription, getSelectorValue } from '../utils';

type Props = {
  firstField: string,
  defaultValue?: SelectorCondition,
  onChange(value: SelectorCondition): void,
};

const ConditionDebitOrCredit = ({ onChange, firstField, defaultValue }: Props): JSX.Element => {
  const { t } = useTranslation();
  const attributeTextRef = useRef<HTMLInputElement | null>(null);
  const [state, setState] = useState({
    date: undefined as Date | [Date, Date] | undefined,
    filterType: defaultValue?.filterType,
    filterPriority: defaultValue?.filterPriority || Object.values(SelectorFilterPriority)[0],
    dispatchType: defaultValue?.dispatch?.type || Object.values(SelectorDispatchType)[0],
    dispatchOperator: defaultValue?.dispatch?.value?.split(' ')[0] || '==',
    dispatchReference: defaultValue?.dispatch?.value?.split(' ')[1] || Object.values(SelectorDispatchReference)[0].toUpperCase(),
    dispatchSign: defaultValue?.dispatch?.value?.split(' ')[2] === '-' ? SelectorDateSign.MINUS : SelectorDateSign.PLUS,
    dispatchValue: defaultValue?.dispatch?.value?.split(' ')[3] ?? null,
    dispatchPeriod: defaultValue?.dispatch?.value?.split(' ')[4] || SelectorPeriod.DAYS,
    attribute: defaultValue?.attribute ?? null,
    attributeOperator: defaultValue?.operator,
    attributeValue: defaultValue?.value ? String(defaultValue.value) : null,
    attributeOptions: {} as SelectorsFieldsData,
    attributes: [] as Misc.ValueLabel[],
    attributeValueWidth: 0,
  });

  const isDebit = useMemo(() => (
    SelectorsRefDateEAV.EAV_DEBIT === SelectorsRefDateEAV[firstField as keyof typeof SelectorsRefDateEAV]
  ), [firstField]);

  const updateState = useCallback((field: keyof typeof state, value: any) => (
    setState((prev) => ({ ...prev, [field]: value }))
  ), []);

  useEffect(() => {
    if (!state.attribute) {
      return;
    }

    const data = getFieldDescription(state.attribute, t);

    updateState('attributeOptions', data);

    if (!state.attributeOperator || !data?.operators?.includes(state.attributeOperator)) {
      updateState('attributeOperator', data?.operators?.[0]);
    }

    if (
      !state.attributeValue ||
      (data?.type === SelectorsFieldsTypes.SELECT &&
        !data?.options?.some((obj) => obj.value === state.attributeValue))
    ) {
      updateState('attributeValue', data?.options?.[0]?.value || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.attribute]);

  useEffect(() => {
    if (!firstField) {
      return;
    }

    const data = getFieldDescription(firstField, t);
    updateState('attributes', data?.options || []);

    if (!state.attribute || !data?.options?.some((obj) => obj.value === state.attribute)) {
      updateState('attribute', data?.options?.[0]?.value || null);
      updateState('filterType', Object.values(isDebit ? SelectorDebitFilterType : SelectorCreditFilterType)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstField]);

  useEffect(() => {
    onChange({
      operator: state.attributeOperator as AllOperators,
      attribute: state.attribute!,
      value: getSelectorValue(state.attributeValue),
      condition: LogicOperator.AND,
      filterType: state.filterType as SelectorDebitFilterType | SelectorCreditFilterType,
      filterPriority: state.filterPriority as SelectorFilterPriority,
      dispatch: {
        type: state.dispatchType as SelectorDispatchType,
        value: `${state.dispatchOperator} ${state.dispatchReference} ${state.dispatchSign === SelectorDateSign.PLUS ? '+' : '-'} ${state.dispatchValue} ${state.dispatchPeriod}`,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.attribute,
    state.attributeOperator,
    state.attributeValue,
    state.dispatchOperator,
    state.dispatchPeriod,
    state.dispatchReference,
    state.dispatchSign,
    state.dispatchType,
    state.dispatchValue,
    state.filterPriority,
    state.filterType,
  ]);

  useEffect(() => {
    updateState('attributeValueWidth', attributeTextRef.current?.offsetWidth || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.attributeValue]);

  const handleOperatorOptions = useCallback((operators?: AllOperators[]) => (
    operators?.map((operator) => ({ value: operator, label: t(getI18nOperatorComparison(operator)) }))
  ), [t]);

  return (
    <div className="ConditionDebitOrCredit">
      <div className="ConditionDebitOrCredit__item">
        <dt className="ConditionDebitOrCredit__item__label">{t('selectors:filter')}</dt>
        <dd className="ConditionDebitOrCredit__item__value">
          <FormSelectMinimal
            name="filterType"
            value={state.filterType}
            onSelect={(_, newVal) => updateState('filterType', newVal)}
            selectOptions={Object.values(
              isDebit ? SelectorDebitFilterType : SelectorCreditFilterType,
            ).map((value) => ({
              label: t(`selectors:selector-types.filter-type.${isDebit ? 'debit' : 'credit'}.${value.replaceAll('_', '-')}`),
              value,
            }))}
          />
        </dd>
      </div>

      <div className="ConditionDebitOrCredit__item">
        <dt className="ConditionDebitOrCredit__item__label">{t('selectors:attribute')}</dt>
        <dd className="ConditionDebitOrCredit__item__value">
          <FormSelectMinimal
            name="attribute"
            value={state.attribute}
            onSelect={(_, newValue: string | null) => { updateState('attribute', newValue); }}
            selectOptions={state.attributes}
          />

          <FormSelectMinimal
            name="operator"
            value={state.attributeOperator}
            onSelect={(_, newVal) => updateState('attributeOperator', newVal)}
            selectOptions={handleOperatorOptions(
              state.attributeOptions?.operators as AllOperators[] | undefined,
            )}
          />
          <div style={{ ['--size' as string]: `${state.attributeValueWidth}px` }}>
            {state.attributeOptions?.type === SelectorsFieldsTypes.SELECT &&
              <FormSelectMinimal
                name={state.attribute ?? ''}
                selectOptions={state.attributeOptions?.options}
                value={state.attributeValue}
                onSelect={(_, newVal) => updateState('attributeValue', newVal)}
              />
            }
            {state.attributeOptions?.type === SelectorsFieldsTypes.TEXT &&
              <FormControl
                name={state.attribute ?? ''}
                defaultValue={state.attributeValue ?? ''}
                onChange={(nawValue) => updateState('attributeValue', nawValue)}
                type="text"
                autoComplete="off"
              />
            }
            {state.attributeOptions?.type === SelectorsFieldsTypes.NUMBER &&
              <FormControl
                name={state.attribute ?? ''}
                defaultValue={state.attributeValue ?? ''}
                onChange={(nawValue) => updateState('attributeValue', nawValue)}
                type="number"
                step={0.01}
              />
            }
            {state.attributeOptions?.type === SelectorsFieldsTypes.DATE &&
              <DatePicker
                value={state.date}
                onChange={(newDate: Date) => {
                  updateState('date', newDate);
                  updateState('attributeValue', newDate ? dateFormatIso(newDate) : null);
                }}
              />
            }
            <div className="ConditionDebitOrCredit__item__value__text" ref={attributeTextRef}>
              {state.attributeOptions?.type === SelectorsFieldsTypes.SELECT ? (
                state.attributeOptions?.options?.find((item) => item.value === state.attributeValue)?.label
              ) : state.attributeValue}
            </div>
          </div>
        </dd>
      </div>

      <div className="ConditionDebitOrCredit__item">
        <dt className="ConditionDebitOrCredit__item__label">{t('selectors:priority')}</dt>
        <dd className="ConditionDebitOrCredit__item__value">
          <FormSelectMinimal
            name="filterPriority"
            defaultValue={state.filterPriority}
            onSelect={(_, newVal) => updateState('filterPriority', newVal)}
            selectOptions={Object.values(SelectorFilterPriority).map((value) => ({
              label: t(`selectors:selector-types.filter-priority.${value.replaceAll('_', '-')}`),
              value,
            }))}
          />
        </dd>
      </div>

      <div className="ConditionDebitOrCredit__item">
        <dt className="ConditionDebitOrCredit__item__label">{t('selectors:dispatcher')}</dt>
        <dd className="ConditionDebitOrCredit__item__value">
          <FormSelectMinimal
            name="dispatchType"
            defaultValue={state.dispatchType}
            onSelect={(_, newVal) => updateState('dispatchType', newVal)}
            selectOptions={Object.values(SelectorDispatchType).map((value) => ({
              label: t(`selectors:selector-types.dispatch.${value.replaceAll('_', '-')}`),
              value,
            }))}
          />
          <FormSelectMinimal
            name="dispatchOperator"
            defaultValue={state.dispatchOperator}
            onSelect={(_, newVal) => updateState('dispatchOperator', newVal)}
            selectOptions={handleOperatorOptions([
              '==' as Partial<EqualityOperators>,
              ...Object.values(ComparisonOperators),
            ])}
          />
          <FormSelectMinimal
            name="dispatchReference"
            defaultValue={state.dispatchReference}
            onSelect={(_, newVal) => updateState('dispatchReference', newVal)}
            selectOptions={Object.values(SelectorDispatchReference).map((value) => ({
              label: t(`selectors:selector-types.dispatch.${value}`),
              value: value.toUpperCase(),
            }))}
          />
          <FormSelectMinimal
            name="dispatchSign"
            defaultValue={state.dispatchSign}
            onSelect={(_, newVal) => updateState('dispatchSign', newVal as SelectorDateSign | null)}
            selectOptions={Object.values(SelectorDateSign).map((value) => ({
              label: t(`selectors:selector-types.${value}`),
              value,
            }))}
          />
          <FormControl
            name="dispatchValue"
            defaultValue={state.dispatchValue ?? ''}
            onChange={(newVal) => updateState('dispatchValue', newVal)}
            type="number"
            step={0.01}
          />
          <FormSelectMinimal
            name="dispatchPeriod"
            defaultValue={state.dispatchPeriod}
            onSelect={(_, newVal) => updateState('dispatchPeriod', newVal as SelectorPeriod | null)}
            selectOptions={Object.values(SelectorPeriod).map((value) => ({
              label: t(`selectors:selector-types.${value}`),
              value,
            }))}
          />
        </dd>
      </div>

    </div>
  );
};

export default ConditionDebitOrCredit;
