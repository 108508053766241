import './index.scss';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import Config from 'config';
import Modal from 'components/Modal';

type Props = {
  data: string,
  type: 'pdf' | 'text' | 'img',
  title: string,
  onClose: () => void,
};

const SendingActionsView = (props: Props): JSX.Element => {
  const { data, type, title, onClose } = props;

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      isOpened
      className="SendingActionsView"
      title={title}
      onClose={handleClose}
    >
      {/* TODO: Utiliser le component PDFViewer */}
      {type === 'pdf' && (
        <div className="SendingActionsView__pdf">
          <object
            className="SendingActionsView__pdf__content"
            type="application/pdf"
            width="100%" height="300px"
            data={`${Config.UPLOADS_BASE_URL}${data}#view=fit`}
          />
        </div>
      )}
      {type === 'img' && (
        <div className="SendingActionsView__img">
          <img
            className="SendingActionsView__img__content"
            alt="IMG"
            src={`${Config.UPLOADS_BASE_URL}${data}`}
            height={300}
          />
        </div>
      )}
      {type === 'text' && (
        <div className="SendingActionsView__text">
          <div
            className="SendingActionsView__text__content"
              /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: data }}
          />
        </div>
      )}
    </Modal>
  );
};

export default observer(SendingActionsView);
