import './index.scss';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import customerContext from 'pages/Customer/customerContext';
import { ExternallyMonitoredSwitch, ExternallyMonitoredController } from 'components/ExternallyMonitored';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import { UserRightsManagerController } from 'components/UserRights';
import { RightsCategoryType } from 'types/models';
import { clientUserToManagerType } from 'utils/mapping/user-manager';
import CustomerActionCreateNote from './CreateNote';
import CustomerActionNewContact from './NewContact';
import CustomerActionNewDebit from './NewDebit';
import CustomerActionNewCredit from './NewCredit';
import CustomerActionDelete from './Delete';
import CustomerActionEdit from './Edit';
import CustomerActionMute from './Mute';
import CustomerActionCreateReminder from './CreateReminder';
import CustomerActionCreateTask from './CreateTask';
import CustomerLaunchSequence from './LaunchSequence';

type Props = {
  onActionError(message: string): void,
};

const CustomerHeaderActions = ({ onActionError }: Props): JSX.Element | null => {
  const { data: customer } = useContext(customerContext);
  const { t } = useTranslation();

  const managers = useMemo(() => {
    return customer?.users ? customer.users.map((user) => clientUserToManagerType(user)) : [];
  }, [customer?.users]);

  if (!customer) {
    return null;
  }

  const { hasDunningAssignment } = customer;

  return (
    <div className="CustomerHeaderActions">
      <ExternallyMonitoredSwitch>
        <ExternallyMonitoredController isDisplayedWhenMonitored>
          <CustomerActionCreateNote variant="outline" />
        </ExternallyMonitoredController>
        <ExternallyMonitoredController isDisplayedWhenMonitored={false}>
          <UserRightsManagerController action="UPDATE" categoryType={RightsCategoryType.CONTACTS}>
            <Dropdown
                className="CustomerHeaderActions__DropdownActions"
                variant="outline"
                toggleTitle={t<string>('common:more-actions')}
                noIcon
                toggle={
                  <div className="CustomerHeaderActions__DropdownActions__toggle">
                    <div className="CustomerHeaderActions__DropdownActions__toggle__label">
                      {t('common:add')}
                    </div>
                    <Icon name="dots" />
                  </div>
                }
              >
                {/* Créer une relance */}
                {hasDunningAssignment && (<CustomerActionCreateReminder />)}
                {/* Créer une tâche */}
                {hasDunningAssignment && (<CustomerActionCreateTask />)}
                {/* Créer une note */}
                <CustomerActionCreateNote variant="list" />
                {/* Mettre en silencieux */}
                <CustomerActionMute managers={managers} onError={onActionError} />
                {/* Lancer une séquence */}
                <CustomerLaunchSequence managers={managers}/>
                <hr className="CustomerHeaderActions__separator" />
                {/* Modifier le client */}
                <CustomerActionEdit managers={managers} onError={onActionError} />
                <hr className="CustomerHeaderActions__separator" />
                {/* Ajouter un contact */}
                <CustomerActionNewContact managers={managers} />
                {/* Ajouter un débit */}
                <CustomerActionNewDebit managers={managers} />
                {/* Ajouter un crédit */}
                <CustomerActionNewCredit managers={managers} />
                <hr className="CustomerHeaderActions__separator" />
                {/* Supprimer le client */}
                <CustomerActionDelete managers={managers} onError={onActionError} />
            </Dropdown>
          </UserRightsManagerController>
        </ExternallyMonitoredController>
      </ExternallyMonitoredSwitch>
    </div>
  );
};

export default CustomerHeaderActions;
