import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import customerContext from 'pages/Customer/customerContext';
import CustomerEditModal from 'components/CustomerEdit';
import Button from 'components/Button';
import { UserRightsManagerController } from 'components/UserRights';
import { ManagerType, RightsCategoryType } from 'types/models';

type Props = {
  managers: ManagerType[] | null | undefined,
  onError(message: string): void,
};

const CustomerActionEdit = ({ managers }: Props): JSX.Element | null => {
  const { onActionDone, data: customer } = useContext(customerContext);
  const { t } = useTranslation();

  const handleEditDone = useCallback((message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showEditModal, hideEditModal] = useModal(() => (
    <CustomerEditModal
      onDone={handleEditDone}
      editType="edit"
      customerId={customer?.id}
      onClose={hideEditModal}
    />
  ), [handleEditDone, customer]);

  return (
    <UserRightsManagerController
      action="UPDATE"
      categoryType={RightsCategoryType.CLIENTS}
      managers={managers}
    >
      <Button
        variant="list"
        className="CustomerActionEdit"
        onClick={showEditModal}
      >
        {t('clients:edit-client')}
      </Button>
    </UserRightsManagerController>
  );
};

export default CustomerActionEdit;
