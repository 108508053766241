import './index.scss';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import ButtonWithUserRights from 'components/ButtonWithUserRights';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Confirm from 'components/Confirm';
import useConfirm from 'components/Confirm/useConfirm';
import organizationStore from 'stores/Organization';
import apiOrganization from 'api/organization';
import apiBusinessUnits from 'api/business-units';
import useApiRequest from 'hooks/useApiRequest';
import { Organization, RightsCategory } from 'types/models';
import SendingActionsView from './View/index';
import SendingActionsEdit from './Edit/index';

type Props = {
  data: string | null,
  businessUnitId?: number,
  type: 'pdf' | 'text' | 'img',
  genreTranslate?: 'm' | 'f',
  title: string,
  name: string,
  onShowToast(message: string, status: ToastStatus): void,
};

const SendingActions = (props: Props): JSX.Element => {
  const { currentOrganization } = organizationStore;
  const { data, type, genreTranslate = 'f', title, name, onShowToast, businessUnitId } = props;
  const { t } = useTranslation();
  const { put, postFormData } = useApiRequest();

  const {
    showConfirm,
    hideConfirm,
    isConfirmShowed,
    confirmTitle,
    confirmText,
  } = useConfirm();

  const handleConfirmDelete = useCallback(() => {
    showConfirm(t('customization:confirm.signature-delete-title'), t('customization:confirm.signature-delete-caption'));
  }, [showConfirm, t]);

  const [showModalView, hideModalView] = useModal(() => (
    <SendingActionsView data={data || ''} type={type} title={title} onClose={hideModalView} />
  ), [data, type, title]);

  const [showModalEdit, hideModalEdit] = useModal(() => (
    <SendingActionsEdit
      data={data}
      type={type}
      title={title}
      name={name}
      onClose={hideModalEdit}
      businessUnitId={businessUnitId}
      onShowToast={onShowToast}
    />
  ), [data, type, title, name, onShowToast, businessUnitId]);

  const handleSubmitDelete = useCallback(async () => {
    if (!currentOrganization) {
      return;
    }

    let result = null;
    if (type === 'pdf' || type === 'img') {
      const formDataFile = new FormData();

      let formDataName = name;
      if (name === 'letterHead') {
        formDataName = 'letter_head';
      } else if (name === 'handwrittenSignature') {
        formDataName = 'signature';
      }

      formDataFile.append(formDataName, '');
      const url = businessUnitId ? apiBusinessUnits.addFileUrl(businessUnitId) : apiOrganization.addFileUrl(currentOrganization.id);
      result = await postFormData<Organization>(url, formDataFile);

    } else {
      const url = businessUnitId ? apiBusinessUnits.updateUrl(businessUnitId) : apiOrganization.updateUrl(currentOrganization.id);
      result = await put<Organization>(url, { [name] : null });
    }

    if (result?.id) {
      organizationStore.refresh();
      hideConfirm();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    }
  }, [
    businessUnitId,
    currentOrganization,
    hideConfirm,
    onShowToast,
    postFormData,
    put,
    name,
    type,
    t,
  ]);

  return (
    <div className="SendingActions">
      {data
        ? (
          <>
            <Button variant="link" onClick={showModalView}>
              {t('common:display')}
            </Button>
            <ButtonWithUserRights
              action="UPDATE"
              category={RightsCategory.PREFERENCES_CUSTOMIZATION_SEND}
              variant="link"
              className="SendingActions__button"
              onClick={showModalEdit}
            >
              {t('common:edit')}
            </ButtonWithUserRights>
            <Button variant="link" onClick={handleConfirmDelete}>
              <Icon name="delete" />
            </Button>
          </>
        )
        : (
          <>
            <div className="SendingActions__noValue">
              {genreTranslate === 'm' ? t('common:none') : t('common:none_f')}
            </div>
            <ButtonWithUserRights
              category={RightsCategory.PREFERENCES_CUSTOMIZATION_SEND}
              action="UPDATE"
              variant="link"
              className="SendingActions__button"
              onClick={showModalEdit}
            >
              {t('customization:add')}
            </ButtonWithUserRights>
          </>
        )}

      <Confirm
        titleModal={confirmTitle}
        text={confirmText}
        isShow={isConfirmShowed}
        onConfirm={handleSubmitDelete}
        onCancel={hideConfirm}
        variant="danger"
        confirmButtonText={t('common:remove')}
      />
    </div>
  );
};

export default SendingActions;
