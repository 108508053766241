import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'react-modal-hook';
import customerContext from 'pages/Customer/customerContext';
import CustomerCreateTask from 'pages/Customer/Modals/CreateTask';
import Button from 'components/Button';
import Icon from 'components/Icon';

type Props = {
  disabled?: boolean,
};

const CustomerActionCreateTask = ({ disabled }: Props): JSX.Element => {
  const { onActionDone, data } = useContext(customerContext);
  const { t } = useTranslation();

  const handleActionDone = useCallback((title: string, message: string) => {
    if (onActionDone) {
      onActionDone(message);
    }
  }, [onActionDone]);

  const [showEditTask, hideEditTask] = useModal(() => (
    disabled ? null : (
      <CustomerCreateTask
        onClose={hideEditTask}
        onDone={handleActionDone}
        customerId={data?.id}
      />
    )
  ), [data?.id, disabled, handleActionDone]);

  return (
    <Button
      variant="list"
      className="CustomerActionCreateTask"
      onClick={showEditTask}
      disabled={!!disabled}
    >
      <Icon name="list" className="CustomerActionCreateTask__icon" />
      {t('clients:create-task')}
    </Button>
  );
};

export default CustomerActionCreateTask;
