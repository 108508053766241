import './index.scss';
import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import organizationStore from 'stores/Organization';
import useApiRequest from 'hooks/useApiRequest';
import useIsMountedRef from 'hooks/useIsMountedRef';
import ModalForm, { ModalFormData } from 'components/ModalForm';
import ErrorMessage from 'components/ErrorMessage';
import Confirm from 'components/Confirm';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import apiBusinessUnits from 'api/business-units';
import apiOrganization from 'api/organization';
import type { Organization } from 'types/models';
import SendingActionsEditForm from './Form';

type Props = {
  data: string | null,
  businessUnitId?: number,
  type: 'pdf' | 'text' | 'img',
  title: string,
  name: string,
  onClose(): void,
  onShowToast(message: string, status: ToastStatus): void,
};

const SendingActionsEdit = (props: Props): JSX.Element => {
  const { data, type, title, name, onClose, onShowToast, businessUnitId } = props;
  const { t } = useTranslation();
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();

  const {
    put,
    postFormData,
    isLoading,
    cancel,
    error,
  } = useApiRequest();

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  const closeSelf = useCallback(() => {
    onClose();
    setShowCancelConfirm(false);
    setHasChanges(false);
  }, [onClose]);

  const handleChange = useCallback((formData: ModalFormData | null) => {
    setHasChanges(!!formData);
  }, []);

  const handleCancel = useCallback(() => {
    if (hasChanges) {
      setShowCancelConfirm(true);
    } else {
      closeSelf();
    }
  }, [hasChanges, closeSelf]);

  useEffect(() => (
    () => { cancel(); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [currentOrganization]);

  const handleSubmit = (async (formData: ModalFormData | null) => {
    if (!formData || !currentOrganization) {
      return;
    }

    let result = null;

    if (type === 'pdf' || type === 'img') {
      const formDataFile = new FormData();

      let formDataName = name;
      if (name === 'letterHead') {
        formDataName = 'letter_head';
      } else if (name === 'handwrittenSignature') {
        formDataName = 'signature';
      }

      formDataFile.append(formDataName, formData[name]);
      const url = businessUnitId ? apiBusinessUnits.addFileUrl(businessUnitId) : apiOrganization.addFileUrl(currentOrganization.id);
      result = await postFormData<Organization>(url, formDataFile);

    } else {
      const url = businessUnitId ? apiBusinessUnits.updateUrl(businessUnitId) : apiOrganization.updateUrl(currentOrganization.id);
      result = await put<Organization>(url, formData);
    }

    if (!isMountedRef.current) {
      return;
    }

    if (result?.errors) {
      setValidationErrors(result.errors);
      return;
    }

    if (result?.id) {
      organizationStore.refresh();
      onClose();
      onShowToast(t('users:toast.success.settings-update'), 'success');
    }
  });

  return (
    <ModalForm
      title={title}
      className="SendingActionsEdit"
      isOpened
      onChange={handleChange}
      hasWarning={hasChanges}
      onSave={handleSubmit}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      {error && <ErrorMessage error={error} />}
      <SendingActionsEditForm
        defaultData={data}
        type={type}
        name={name}
        errors={validationErrors}
      />
      <Confirm
        titleModal={t('common:confirm-cancel-form')}
        text={t('common:confirm-loose-all-modifications')}
        variant="danger"
        confirmButtonText={t('common:close-form')}
        cancelButtonText={t('common:stay-on-form')}
        isShow={showCancelConfirm}
        onConfirm={closeSelf}
        onCancel={() => { setShowCancelConfirm(false); }}
        isDemoSafe
      />
    </ModalForm>
  );
};

export default observer(SendingActionsEdit);
