import './index.scss';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import fileDownload from 'js-file-download';
import { uniq } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getContentBase64String } from 'utils/base64';
import { Channel } from 'types/models';
import type { HistoryStepPreviewContent } from 'types/models';
import normalizeActionName from 'utils/normalizeActionName';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import CountCharactersHelper from 'components/CountCharactersHelper';
import PDFViewer from 'components/PDFViewer';
import apiHistorySteps from 'api/historySteps';
import Icon from 'components/Icon';
import Alert from 'components/Alert';
import Button from 'components/Button';
import ListElement from 'components/ListElement';
import Dropdown from 'components/Dropdown';
import Misc from 'types/misc';

type ReminderProps = {
  code: string,
  isEditable: boolean | null,
  channel: Channel | null,
  name?: string | null,
  description?: string | null,
  subject?: string | null,
  content?: string | null,
};

type Props = {
  reminder: ReminderProps,
  onClose(): void,
};
const ReminderPreview = ({ reminder, onClose }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [error, setError] = useState<Error | null>(null);
  const [reminders, setReminders] = useState<HistoryStepPreviewContent[]>([]);
  const [currentReminder, setCurrentReminder] = useState<HistoryStepPreviewContent>();
  const [selectedContactRole, setSelectedContactRole] = useState<Readonly<Misc.ValueLabel | null>>();
  const [selectedSender, setSelectedSender] = useState<Readonly<string | null>>();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (reminders.length !== 0) {
      return;
    }

    const fetchReminder = async () => {
      const { code, channel, name, description, subject, content, isEditable } = reminder;
      if (!channel) {
        throw new Error('Action non définie.');
      }

      setLoading(true);

      try {
        const response = isEditable
          ? await apiHistorySteps.fetchPreviewContent({ code, name, description, subject, content })
          : await apiHistorySteps.fetchHistoryContent(code);

        setReminders(response);
        if (response.length > 0) {
          setCurrentReminder(response[0]);
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }

    };

    fetchReminder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminder]);

  const channelAttributes = useMemo(() => {
    if (!reminder.channel) {
      throw new Error('Action non définie.');
    }

    const attributes = {
      [Channel.EMAIL]: {
        subTitle: t('clients:reminder-preview.email'),
        modifier: 'email',
      },
      [Channel.SMS]: {
        subTitle: t('clients:reminder-preview.sms'),
        modifier: 'sms',
      },
      [Channel.LETTER]: {
        subTitle: t('clients:reminder-preview.letter'),
        modifier: 'letter',
      },
      [Channel.LETTER_REGISTERED]: {
        subTitle: t('clients:reminder-preview.registered-letter'),
        modifier: 'letter',
      },
    };

    return attributes[reminder.channel];
  }, [t, reminder.channel]);

  const sanitizedContent = useMemo(() => (
    currentReminder?.content ?? ''
  ), [currentReminder]);

  const wrapperClassName = useMemo(() => classnames(
    'ReminderPreview__wrapper',
    `ReminderPreview__wrapper--${channelAttributes.modifier}`,
  ), [channelAttributes]);

  const sendersList: Readonly<string>[] = useMemo(() => {
    if (!reminders) {
      return [];
    }

    const senders = reminders.flatMap(({ contact }) => (
      contact
        .filter(({ senderEmail }) => !!senderEmail)
        .map(({ senderEmail }) => senderEmail)
    ));

    return uniq(senders);
  }, [reminders]);

  const contactRoleList: Readonly<Misc.ValueLabel>[] = useMemo(() => {
    if (!reminders) {
      return [];
    }

    return reminders.flatMap(({ contact }) => (
      contact.map(({ contactRole, contactRoleLabel, fullName }) => ({
        value: contactRole,
        label: `${fullName ? fullName : ''}${contactRoleLabel ? `${fullName ? ' (' : ''}${contactRoleLabel}${fullName ? ')' : ''}` : '' }`,
      }))
    ));
  }, [reminders]);

  useEffect(() => {
    if (selectedContactRole) {
      return;
    }

    setSelectedContactRole(
      contactRoleList.length ? contactRoleList[0] : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoleList]);

  useEffect(() => {
    if (selectedSender) {
      return;
    }

    setSelectedSender(
      sendersList.length ? sendersList[0] : null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendersList]);

  const handleSenderChange = useCallback((newValue: Readonly<string | null>) => {
    setSelectedSender(newValue);
    const newCurrentReminder = reminders?.find((reminderPreview) => (
      reminderPreview.contact.some((contact) => contact.senderEmail === newValue)
    ));
    if (newCurrentReminder) {
      setCurrentReminder(newCurrentReminder);
    }
  }, [reminders]);

  const handleContactRoleChange = useCallback((newValue: Readonly<Misc.ValueLabel | null>) => {
    setSelectedContactRole(newValue);
    const newCurrentReminder = reminders?.find((reminderPreview) => (
      reminderPreview.contact.some((contact) => contact.contactRole === newValue?.value)
    ));
    if (newCurrentReminder) {
      setCurrentReminder(newCurrentReminder);
    }
  }, [reminders]);

  const isLetter = normalizeActionName(reminder.channel) === Channel.LETTER;

  const onDownloadTemplateClick = useCallback(async () => {
    if (currentReminder) {
      const pdfContent = getContentBase64String(currentReminder.content!);
      fileDownload(pdfContent, 'LETTER.pdf');
    }
  }, [currentReminder]);

  const renderHeaderActions = useMemo(() => {
    if (isLetter && !isLoading && currentReminder) {
      if (currentReminder.presignUrl) {
        return (
          <Link
            to={{ pathname: currentReminder.presignUrl }}
            target="_blank"
            className="Button Button--link ReminderPreview__download"
          >
            <Icon name='download' />
            <div className="ReminderPreview__download__text">{t('common:download')}</div>
          </Link>
        );
      } else if (currentReminder.content) {
        return <Button
          variant="link"
          className="ReminderPreview__download"
          onClick={onDownloadTemplateClick}
        >
          <Icon name='download' />
          <div className="ReminderPreview__download__text">{t('common:download')}</div>
        </Button>;
      }
    }
  }, [currentReminder, isLetter, isLoading, onDownloadTemplateClick, t]);

  return (
    <Modal
      isOpened
      title={`${t('common:preview')} ${channelAttributes.subTitle}`}
      headerActions = {renderHeaderActions}
      onClose={onClose}
    >
      <div className="ReminderPreview">
        {error &&
          <Alert variant="error">
            {t('errors:unknown-retry')}
          </Alert>
        }
        {!isLoading && !!currentReminder && (
          <dl className="ReminderPreview_item">
            {reminder.channel === Channel.EMAIL && (
              <ListElement label={t('reminders:sender')}>
              {!!sendersList.length ?
                <Dropdown
                  variant="link"
                  className="ReminderPreview__senders"
                  toggle={selectedSender}
                >
                  {sendersList.map((value) => (
                    <Button
                      variant="list"
                      key={`group-${value}`}
                      className={classnames('ReminderPreview_recipients__item', {
                        'ReminderPreview_recipients__item--active': selectedSender === value,
                      })}
                      onClick={() => { handleSenderChange(value); }}
                    >
                      {value}
                    </Button>
                  ))}
                </Dropdown> : '-'
              }
              </ListElement>
            )}
            <ListElement label={t('reminders:recipient')}>
              <Dropdown
                variant="link"
                className="ReminderPreview__recipients"
                toggle={selectedContactRole?.label ?? '-'}
              >
                {contactRoleList?.map(({ value, label }) => (
                  <Button
                    variant="list"
                    key={`group-${value}`}
                    className={classnames('ReminderPreview_recipients__item', {
                      'ReminderPreview_recipients__item--active': selectedContactRole?.value === value,
                    })}
                    onClick={() => { handleContactRoleChange({ value, label }); }}
                  >
                    {label ?? '-'}
                  </Button>
                ))}
              </Dropdown>
            </ListElement>
            {reminder.channel === Channel.EMAIL && (
              <ListElement label={t('common:object')}>
                {currentReminder.subject ? currentReminder.subject : '-'}
              </ListElement>
            )}

            {!!Object.keys(currentReminder.errors).length ?
              <Alert variant="error">
                {currentReminder.errors.exit_description}
              </Alert> :
              <Fragment>
                <ListElement label={t('reminders:content')}>
                  {(currentReminder.content && isLetter) && (
                    <div className={wrapperClassName}>
                      <PDFViewer base64PDF={currentReminder.content} />
                    </div>
                  )}
                  {(currentReminder.content && !isLetter) && (
                    <div className={wrapperClassName}>
                      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }}/>
                    </div>
                  )}
                </ListElement>
                {currentReminder.content && !isLetter && reminder.channel === Channel.SMS && (
                  <ListElement label={t('common:length')}>
                    <CountCharactersHelper
                      number={currentReminder.content.length}
                    />
                  </ListElement>
                )}
              </Fragment>
            }
          </dl>
        )}
        {isLoading && <Loading />}
      </div>
    </Modal>
  );
};

export default observer(ReminderPreview);
