import { useTranslation } from 'react-i18next';
import type { ManagerType } from 'types/models';
import { RightsCategoryType } from 'types/models';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { UserRightsManagerController } from 'components/UserRights';

type Props = {
  managers: ManagerType[] | null | undefined,
};

const CustomerLaunchSequence = ({ managers }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <UserRightsManagerController
      action="CREATE"
      categoryType={RightsCategoryType.CLIENTS}
      managers={managers}
    >
      <Button
        className="CustomerActionLaunchSequence"
        variant="list"
        title="Bientôt disponible"
        disabled
      >
        <Icon name="sequence" />
        {t('clients:launch-sequence')}
      </Button>
    </UserRightsManagerController>
  );
};

export default CustomerLaunchSequence;
