import './index.scss';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import type { ToastStatus } from 'components/ToastNotification/useToast';
import config from 'config';
import SettingsList from 'pages/Settings/List';
import organizationStore from 'stores/Organization';
import useFetch from 'hooks/useFetch2';
import apiOrganization from 'api/organization';
import type { PeriodicTasksParams } from 'api/organization';
import type { BusinessUnit, OrganizationPeriodicTasks } from 'types/models';
import HelpLink from 'components/HelpLink';
import SettingsSection from '../../Section';
import SendingMode from './SendingMode';
import SendingSchedule from './SendingSchedule';
import Signatures from './Signatures';

type Props = {
  onShowToast(message: string, status: ToastStatus): void,
};

const Sending = ({ onShowToast }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { currentOrganization, businessUnits } = organizationStore;

  const { data: periodicTasks } = useFetch<PeriodicTasksParams, OrganizationPeriodicTasks>(
    {
      cacheKey: 'OrganizationPeriodicTasks',
      organization: currentOrganization!.id,
    },
    apiOrganization.getPeriodicTasks,
    { enabled: !!currentOrganization!.id },
  );

  const listDataSendingSchedules = useMemo(() => {
    if (!periodicTasks) {
      return [];
    }

    return [
      {
        label: t('customization:list-sending.sending-mode'),
        value: (
          <SendingMode
            timeZone={periodicTasks.timezone}
            defaultValue={periodicTasks.mode!}
            lastExecutedAt={periodicTasks.lastExecutedAt!}
            onShowToast={onShowToast}
          />
        ),
      },
      {
        label: t('customization:list-sending.schedule-email-sms'),
        help: <HelpLink article={config.FRESHDESK_SCHEDULE} />,
        value: (
          <SendingSchedule
            data={periodicTasks}
            onShowToast={onShowToast}
          />
        ),
      },
    ];
  }, [periodicTasks, t, onShowToast]);

  const sortedData = useMemo<BusinessUnit[]>(() => {
    const data = [...businessUnits];
    return data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }, [businessUnits]);

  return (
    <div className="SendingCustomization">
      <SettingsSection title={t('customization:signatures')}>
        <div className="Sending__signatures">
          <Signatures
            standalone={businessUnits.length === 0}
            data={currentOrganization!}
            onShowToast={onShowToast}
          />
          {sortedData.map((businessUnit) => (
            <Signatures
              key={businessUnit.id}
              data={businessUnit}
              onShowToast={onShowToast}
            />
          ))}
        </div>
      </SettingsSection>
      <SettingsSection title={t('customization:sending')}>
        <div className="Sending__list">
          <div className="Sending__list__items">
            <SettingsList data={listDataSendingSchedules} />
          </div>
        </div>
      </SettingsSection>
    </div>
  );
};

export default observer(Sending);
