import './index.scss';
import classNames from 'classnames';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValidationErrors } from 'types/errors';
import type { AllOperators, SelectorCondition } from 'types/models';
import { LogicOperator, SelectorsRefDateEAV } from 'types/models';
import Button from 'components/Button';
import DropdownActions from 'components/DropdownActions';
import ConditionSentence from 'pages/Settings/Selectors/ConditionSentence';
import { getAttributeValue, getSelectorValue } from './utils';
import ConditionField from './ConditionField';
import ConditionDebitOrCredit from './ConditionDebitOrCredit';

type Props = {
  errors?: ValidationErrors | null,
  defaultCondition?: SelectorCondition,
  index: number,
  onChange(value: SelectorCondition, index: number): void,
  onRemove(index: number): void,
  onToggleConditionsOpen(newOpen: boolean): void,
  shouldLockConditions?: boolean,
};

const Conditions = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const {
    errors,
    defaultCondition,
    index,
    onToggleConditionsOpen,
    onChange,
    onRemove,
    shouldLockConditions = false,
  } = props;

  const [firstField, setFirstField] = useState<string | null>(
    getAttributeValue(defaultCondition?.attribute),
  );
  const [thirdField, setThirdField] = useState<string | boolean | number | null>(
    defaultCondition?.value ?? null,
  );
  const [secondField, setSecondField] = useState<string | null>(defaultCondition?.attribute ?? null);
  const [operator, setOperator] = useState<string | null>(defaultCondition?.operator ?? null);
  const [searchDefaultValue, setSearchDefaultValue] = useState<boolean>(true);
  const [conditionValue, setConditionValue] = useState<SelectorCondition | undefined>(defaultCondition);

  const isDebitOrCredit = useMemo(() => {
    const { EAV_CREDIT, EAV_DEBIT } = SelectorsRefDateEAV;
    return firstField && [EAV_CREDIT, EAV_DEBIT].includes(SelectorsRefDateEAV[firstField as keyof typeof SelectorsRefDateEAV]);
  }, [firstField]);

  const canValidate = useMemo(() => (
    isDebitOrCredit || (!!operator && !!firstField && !!secondField && thirdField !== null && thirdField !== 'null')
  ), [firstField, secondField, thirdField, operator, isDebitOrCredit]);

  const [isEditing, setIsEditing] = useState<boolean>(!canValidate);
  const [isOpened, setIsOpened] = useState<boolean | undefined>(undefined);

  const handleRemove = useCallback(() => {
    if (!isOpened) {
      onToggleConditionsOpen(true);
    }
    onRemove(index);
  }, [index, isOpened, onRemove, onToggleConditionsOpen]);

  const handleValidate = useCallback(() => {
    if (!canValidate) {
      return;
    }

    setIsEditing(false);

    if (isDebitOrCredit && conditionValue) {
      onChange(conditionValue, index);
      return;
    }

    const newConditionValue: SelectorCondition = {
      operator: operator as AllOperators,
      attribute: secondField!,
      value: getSelectorValue(thirdField),
      condition: LogicOperator.AND,
    };
    setConditionValue(newConditionValue);
    onChange(newConditionValue, index);
  }, [canValidate, secondField, thirdField, operator, onChange, index, conditionValue, isDebitOrCredit]);

  const handleFirstFieldChange = useCallback((newValue: string | null) => {
    setFirstField(newValue);
    setSecondField(null);
    setThirdField(null);
  }, []);

  const handleSecondFieldChange = useCallback((newValue: string | null) => {
    setSecondField(newValue);
    setThirdField(null);
  }, []);

  useEffect(() => {
    if (isOpened !== (!canValidate || isEditing)) {
      onToggleConditionsOpen(!canValidate || isEditing);
      setIsOpened(!canValidate || isEditing);
    }
  }, [canValidate, isEditing, isOpened, onToggleConditionsOpen]);

  const className = classNames('ModalSelectorConditions__summary', {
    'ModalSelectorConditions__summary--disabled': shouldLockConditions,
  });

  return (
    <Fragment>
      {!isOpened && (
        <div className={className}>
          {conditionValue && (
            <ConditionSentence condition={conditionValue} />
          )}
          {!shouldLockConditions && (
            <DropdownActions actions={[
              <Button variant="list" onClick={() => setIsEditing(true)}>
                {t('common:edit')}
              </Button>,
              <Button variant="list-danger" onClick={handleRemove}>
                {t('common:remove')}
              </Button>,
            ]} />
          )}
        </div>
      )}
      {isOpened && (
        <div className='ModalSelectorConditions'>
          <div className="ModalSelectorConditions__fields">
            <ConditionField
              name='FIRST_FIELD'
              onChangeValue={handleFirstFieldChange}
              defaultValue={firstField}
              errors={errors}
            />
            {isDebitOrCredit && firstField &&
              <ConditionDebitOrCredit
                firstField={firstField}
                onChange={setConditionValue}
                defaultValue={defaultCondition}
              />
            }
            {!isDebitOrCredit && firstField &&
              <ConditionField
                name={firstField as string}
                onChangeValue={handleSecondFieldChange}
                defaultValue={secondField}
                onChangeFirstField={setFirstField}
                onChangeSearchDefaultValue={setSearchDefaultValue}
                searchDefaultValue={searchDefaultValue}
                errors={errors}
              />
            }
            {!isDebitOrCredit && secondField &&
              <ConditionField
                name={secondField as string}
                onChangeValue={setThirdField}
                defaultValue={String(thirdField)}
                operatorValue={operator}
                onChangeOperatorValue={setOperator}
                errors={errors}
              />
            }
          </div>
          <div className="ModalSelectorConditions__commands">
            <div className="ModalSelectorConditions__commands__remove">
              <Button variant="link" onClick={handleRemove}>
                {t('selectors:remove-condition')}
              </Button>
            </div>
            <div className="ModalSelectorConditions__commands__validate">
              <Button variant="link" onClick={handleValidate} disabled={!canValidate}>
                {t('common:validate')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Conditions;
