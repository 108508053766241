import './index.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import FileDownload from 'js-file-download';
import { parse } from 'yaml';
import apiSelectors from 'api/selectors';
import { ACCEPT_FILES_TYPE } from 'config';
import organizationStore from 'stores/Organization';
import useIsMountedRef from 'hooks/useIsMountedRef';
import useApiRequest from 'hooks/useApiRequest';
import ModalForm from 'components/ModalForm';
import FormFieldset from 'components/FormFieldset';
import Dropzone from 'components/Dropzone';
import Button from 'components/Button';
import FormCheck from 'components/FormCheck';
import Loading from 'components/Loading';
import Icon from 'components/Icon';

type Props = {
  onClose(): void,
  onActionDone(message: string): void,
  onActionError(message: string): void,
};

const ModalEditYAML = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { onClose, onActionDone } = props;
  const { currentOrganization } = organizationStore;
  const isMountedRef = useIsMountedRef();
  const [replace, setReplace] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [planIsValid, setPlanIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  const { post, error: apiError, isLoading } = useApiRequest();

  const handleDrop = useCallback((files: File[]) => {
    setFile(files[0]);
    setPlanIsValid(false);
    setError(null);
  }, []);

  const handleSubmit = useCallback(
    async () => {
      if (!currentOrganization || !file) {
        return;
      }

      setError(null);
      setPlanIsValid(false);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('organization', currentOrganization.reference);

      if (!replace) {
        try {
          const fileContent = await file.text();
          parse(fileContent);
          formData.append('dryRun', '1');
        } catch (exception: any) {
          setError(exception.message);
          return;
        }
      }

      const { status, failures } = (await post(apiSelectors.importUrl, formData)) as { status: string, failures: string[] };
      if (status === 'FAILED') {
        setError(failures.join('\n'));
        return;
      }

      setPlanIsValid(true);

      if (!isMountedRef.current || !replace) {
        return;
      }
      onActionDone(t('selectors:toast.success.yaml-selectors'));
      onClose();
    },
    [
      currentOrganization,
      file,
      isMountedRef,
      onActionDone,
      onClose,
      post,
      replace,
      t,
    ],
  );

  const onDownloadTemplateClick = useCallback(async () => {
    if (!currentOrganization) {
      return;
    }
    const { data } = await apiSelectors.getTemplate(currentOrganization.reference);
    FileDownload(data, 'selectors.yaml');
  }, [currentOrganization]);

  return (
    <ModalForm
      isOpened
      className="SelectorsModalEditYAML"
      onSave={handleSubmit}
      onCancel={onClose}
      title={t('selectors:selectors-via-yaml')}
      saveText={planIsValid && replace ? t('plans:submit-step-2') : t('plans:submit-step-1')}
    >
      <p className="SelectorsModalEditYAML__description">
        {t('selectors:description-yaml')}
      </p>
      <Button
        className="SelectorsModalEditYAML__download"
        variant="success"
        onClick={onDownloadTemplateClick}
      >
        {t('plans:download-current-file')}
      </Button>
      <p className="SelectorsModalEditYAML__title">{planIsValid ? t('plans:yaml-file-step-2') : t('plans:yaml-file-step-1')}</p>
      <Dropzone
        onDrop={handleDrop}
        onError={(message: string) => setError(message)}
        accept={ACCEPT_FILES_TYPE.YAML}
        maxFiles={1}
        withInstructions={false}
        filename={file?.name}
        icon={<Icon name="yml" />}
      />
      {isLoading && <Loading hasNoText />}
      {!isLoading && (error || apiError) && (
        <div className="SelectorsModalEditYAML__invalid">
          <b>{t('plans:upload-is-invalid-title')}</b>
          <p dangerouslySetInnerHTML={{
            __html: (error || apiError?.message) as string,
          }} />
        </div>
      )}
      {planIsValid && (
        <div className="SelectorsModalEditYAML__valid">
          <b>{t('plans:upload-is-valid-title')}</b>
          <p>{t('plans:upload-is-valid-text', { name: file?.name })}</p>
        </div>
      )}
      {planIsValid && (
        <FormFieldset>
          <p className="SelectorsModalEditYAML__check">{t('selectors:replace-current-selectors')}</p>
          <FormCheck onChange={setReplace} checked={replace} />
        </FormFieldset>
      )}
    </ModalForm>
  );
};

export default observer(ModalEditYAML);
