import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectorsType, AllOperators, DaysOperators, MonthsOperators, SelectorsRefDateEAV } from 'types/models';
import Misc from 'types/misc';
import type { SelectorCondition } from 'types/models';
import getI18nOperatorComparison from 'utils/getI18nOperatorComparison';
import { getAttributeValue, getFieldDescription } from '../ModalSelector/Form/Conditions/utils';

type Props = {
  condition: Partial<SelectorCondition>,
};

const ConditionSentence = ({ condition }: Props): string => {
  const { t } = useTranslation();
  const [sentence, setSentence] = useState<string>('');

  const generateSentence = useCallback(() => {
    let {
      operator,
      attribute:
      secondField,
      value: thirdField,
      filterType,
      filterPriority,
      dispatch,
    } = condition;

    // premier champ (value)
    const firstField = getAttributeValue(secondField);

    // deuxième champ
    const secondFieldData = getFieldDescription(firstField, t);
    const secondFieldOption = secondFieldData?.options?.find((option) => String(option.value) === String(secondField));
    const secondFieldText = secondFieldOption?.label ?? secondField;

    // premier champ (text)
    const withFirstFieldText = !!firstField && !['FREE_FIELD', 'DEBIT'].includes(firstField) && !!secondFieldOption;
    const firstFieldText = withFirstFieldText ? t(`selectors:selector-types.${SelectorsType[firstField!]}`) : '';

    // l'opérateur
    let operatorText = t(getI18nOperatorComparison(operator as AllOperators));

    // troisième champ
    let thirdFieldText;
    let isDays = Object.values(DaysOperators).includes(operator as DaysOperators);
    let isMonths = Object.values(MonthsOperators).includes(operator as MonthsOperators);
    if (isDays || isMonths) {
      const days = Math.abs(parseInt(String(thirdField), 10));
      const sign = String(thirdField).startsWith('-') ? '-' : '+';
      const period = isDays ? 'days' : 'months';
      thirdFieldText = `${sign}${t(`selectors:selector-types.${period}-count`, { count: days })}`;
      operatorText += ` ${t('selectors:selector-types.date')}`;
    } else {
      const thirdFieldData = getFieldDescription(secondField!, t);
      const thirdFieldOptions: Misc.ValueLabel[] = thirdFieldData?.options || [];

      thirdFieldText = (thirdFieldData?.isMultiple
        ? String(thirdField).split(',').map((value) => thirdFieldOptions.find((opt) => String(opt.value) === value.trim())?.label ?? value.trim())
        : [thirdFieldOptions?.find((opt) => String(opt.value) === String(thirdField))?.label ?? thirdField]
      ).join(', ');
    }

    // un crédit ou un débit
    const isDebit = SelectorsRefDateEAV.EAV_DEBIT === SelectorsRefDateEAV[firstField as keyof typeof SelectorsRefDateEAV];
    const isCredit = SelectorsRefDateEAV.EAV_CREDIT === SelectorsRefDateEAV[firstField as keyof typeof SelectorsRefDateEAV];
    let filterTypeText = '', filterPriorityText = '', dispatchText = '';
    if ((isDebit || isCredit) && filterType && filterPriority && dispatch?.value) {
      // Filtrer
      filterTypeText = `${t('selectors:filter')} ${t(`selectors:selector-types.filter-type.${isDebit ? 'debit' : 'credit'}.${filterType.replaceAll('_', '-')}`)} `;

      // Priorité
      filterPriorityText = `${t('selectors:priority')} ${t(`selectors:selector-types.filter-priority.${filterPriority.replaceAll('_', '-')}`)} `;

      // Déclencheur
      const dispatchType = t(`selectors:selector-types.dispatch.${dispatch.type.replaceAll('_', '-')}`);
      let [dispatchOperator, dispatchReference, dispatchSign, dispatchValue, dispatchPeriod] = dispatch.value.split(' ');
      dispatchOperator = t(getI18nOperatorComparison(dispatchOperator as AllOperators));
      dispatchReference = t(`selectors:selector-types.dispatch.${dispatchReference.toLowerCase()}`);
      dispatchPeriod = t(`selectors:selector-types.${dispatchPeriod}`);
      dispatchText = ` ${t('selectors:dispatcher')} ${dispatchType} ${dispatchOperator} ${dispatchReference} ${dispatchSign}${dispatchValue} ${dispatchPeriod}`;
    }

    setSentence(`${filterTypeText}${firstFieldText} ${secondFieldText} ${operatorText} ${thirdFieldText} ${filterPriorityText}${dispatchText}`);
  }, [condition, t]);

  useEffect(() => {
    generateSentence();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return sentence ?? '';
};
export default ConditionSentence;
